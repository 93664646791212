export enum PERMISSIONS {
  FO_MAIN_CAMPAIGN_ACCESS = 'ads_FO_main_campaign_access',
  FO_DISPLAY_CAMPAIGNS_LIST_ACCESS = 'ads_FO_display_campaign_list',
  FO_DISPLAY_CAMPAIGNS_DETAILS_ACCESS = 'ads_FO_display_campaign_details',
  FO_DISPLAY_CAMPAIGNS_CREATE_ACCESS = 'ads_FO_display_campaign_create',
  FO_OFFLINE_ADVERTISING_ACCESS = 'ads_FO_offline_advertising_access',
  FO_INSIGHTS_ACCESS = 'ads_FO_insights_access',
  FO_TOPUP_ACCESS = 'ads_FO_topup_access',
  FO_FINANCE_ACCESS = 'ads_FO_finance_access',
  BO_MAIN_CAMPAIGN_ACCESS = 'ads_BO_main_campaign_access',
  BO_PACKAGES_ACCESS = 'ads_BO_packages_access',
  BO_USER_MANAGEMENT_ACCESS = 'ads_BO_main_campaign_access',
  BO_DISPLAY_CAMPAIGNS_LIST_ACCESS = 'ads_BO_display_campaign_list',
  BO_DISPLAY_CAMPAIGNS_DETAILS_ACCESS = 'ads_BO_display_campaign_details',
  BO_DISPLAY_CAMPAIGNS_MODERATE_ACCESS = 'ads_BO_display_campaign_moderate',
  BO_OFFLINE_ADVERTISING_ACCESS = 'ads_BO_offline_advertising_access',
  BO_INSIGHTS_ACCESS = 'ads_BO_insights_access',
  FO_TIER1_EVENTS_ACCESS = 'ads_FO_tier1_events',
  BO_TIER1_EVENTS_ACCESS = 'ads_BO_tier1_events',
  BO_SETTINGS_ACCESS = 'ads_BO_main_campaign_access',
  // Sponsored Products FO permissions
  FO_SPONSORED_PRODUCTS_LIST = 'ads_FO_sponsored_products_list',
  FO_SPONSORED_PRODUCTS_CREATE = 'ads_FO_sponsored_products_create',
  FO_SPONSORED_PRODUCTS_EDIT = 'ads_FO_sponsored_products_edit',
  FO_SPONSORED_PRODUCTS_DELETE = 'ads_FO_sponsored_products_delete',
  FO_SPONSORED_PRODUCTS_DETAILS = 'ads_FO_sponsored_products_details',
  FO_SPONSORED_PRODUCTS_REPORTS_LIST = 'ads_FO_sponsored_products_reports_list',
  FO_SPONSORED_PRODUCTS_REPORTS_EXPORT = 'ads_FO_sponsored_products_reports_export',
  FO_SPONSORED_PRODUCTS_FINANCE = 'ads_FO_sponsored_products_finance',
  BO_SPONSORED_PRODUCTS_FINANCE = 'ads_BO_sponsored_products_finance',
  FO_SPONSORED_PRODUCTS_FINANCE_TOPUP = 'ads_FO_sponsored_products_finance_topup',
  // Sponsored Products BO permissions
  BO_SPONSORED_PRODUCTS_LIST = 'ads_BO_sponsored_products_list',
  BO_SPONSORED_PRODUCTS_DELETE = 'ads_BO_sponsored_products_delete',
  BO_SPONSORED_PRODUCTS_DETAILS = 'ads_BO_sponsored_products_details',
  BO_SPONSORED_PRODUCTS_MODERATE = 'ads_BO_sponsored_products_moderate',
  BO_SPONSORED_PRODUCTS_SETTINGS_MODERATE_VIEW = 'ads_BO_sponsored_products_settings_moderate_view',
  BO_SPONSORED_PRODUCTS_SETTINGS_MODERATE = 'ads_BO_sponsored_products_settings_moderate',
  BO_SPONSORED_PRODUCTS_EDIT = 'ads_BO_sponsored_products_edit',
  // sponsored brands FO permissions
  FO_SPONSORED_BRANDS_LIST = 'ads_FO_sponsored_brands_list',
  FO_SPONSORED_BRANDS_CREATE = 'ads_FO_sponsored_brands_create',
  FO_SPONSORED_BRANDS_EDIT = 'ads_FO_sponsored_brands_edit',
  FO_SPONSORED_BRANDS_DELETE = 'ads_FO_sponsored_brands_delete',
  FO_SPONSORED_BRANDS_DETAILS = 'ads_FO_sponsored_brands_details',
  // sponsored brands bo permissions
  BO_SPONSORED_BRANDS_LIST = 'ads_BO_sponsored_brands_list',
  BO_SPONSORED_BRANDS_DELETE = 'ads_BO_sponsored_brands_delete',
  BO_SPONSORED_BRANDS_DETAILS = 'ads_BO_sponsored_brands_details',
  BO_SPONSORED_BRANDS_MODERATE = 'ads_BO_sponsored_brands_moderate',
  BO_SPONSORED_BRANDS_SETTINGS_MODERATE = 'ads_BO_sponsored_brands_settings_moderate',
  BO_SPONSORED_BRANDS_SETTINGS_MODERATE_VIEW = 'ads_BO_sponsored_brands_settings_moderate_view',
  // Package BO permissions
  BO_PACKAGES_LIST = 'ads_BO_packages_list',
  BO_PACKAGES_EDIT = 'ads_BO_packages_edit',
  // User Management BO permissions
  BO_USER_MANAGEMENT_LIST = 'ads_BO_main_campaign_access',
  BO_USER_MANAGEMENT_EDIT = 'ads_BO_main_campaign_access',
  // modules permissions
  BO_MODULES_MODERATE = 'ads_BO_modules_moderate',
  // Insights FO permissions
  FO_INSIGHTS_LIST = 'ads_FO_insights_list',
  FO_INSIGHTS_DOWNLOAD = 'ads_FO_insights_download',
  // Insights BO permissions
  BO_INSIGHTS_LIST = 'ads_BO_insights_list',
  BO_INSIGHTS_CREATE = 'ads_BO_insights_create',
  BO_INSIGHTS_DELETE = 'ads_BO_insights_delete',
  BO_INSIGHTS_DOWNLOAD = 'ads_BO_insights_download',
  // Public FO feature permissions
  FO_PUBLIC_FEATURES = 'ads_FO_public_features',
  FO_PUBLIC_FEATURES_PREVIEW = 'ads_FO_preview_features',
  // Public BO feature permissions
  BO_PUBLIC_FEATURES = 'ads_BO_public_features',
  BO_PUBLIC_FEATURES_PREVIEW = 'ads_BO_preview_features',
}

// this roles will be used  to decide which UI will be visible to user
// TODO: replace this with user_type that will be coming from backend
// TODO rename to singular to follow enum names
export enum ROLES {
  VENDOR = 'vendor',
  ADMIN = 'admin',
  GURU = 'guru',
}

export const getPermissions = {
  vendor: [
    PERMISSIONS.FO_MAIN_CAMPAIGN_ACCESS,
    PERMISSIONS.FO_OFFLINE_ADVERTISING_ACCESS,
    PERMISSIONS.FO_INSIGHTS_ACCESS,
    PERMISSIONS.FO_FINANCE_ACCESS,
    PERMISSIONS.FO_TOPUP_ACCESS,
    PERMISSIONS.FO_TIER1_EVENTS_ACCESS,
    PERMISSIONS.FO_SPONSORED_PRODUCTS_LIST,
    PERMISSIONS.FO_SPONSORED_PRODUCTS_CREATE,
    PERMISSIONS.FO_SPONSORED_PRODUCTS_EDIT,
    PERMISSIONS.FO_SPONSORED_PRODUCTS_DELETE,
    PERMISSIONS.FO_SPONSORED_PRODUCTS_DETAILS,
    PERMISSIONS.FO_SPONSORED_PRODUCTS_REPORTS_LIST,
    PERMISSIONS.FO_SPONSORED_PRODUCTS_REPORTS_EXPORT,
    PERMISSIONS.FO_SPONSORED_PRODUCTS_FINANCE,
    PERMISSIONS.FO_SPONSORED_PRODUCTS_FINANCE_TOPUP,
    PERMISSIONS.FO_SPONSORED_BRANDS_LIST,
    PERMISSIONS.FO_SPONSORED_BRANDS_CREATE,
    PERMISSIONS.FO_SPONSORED_BRANDS_EDIT,
    PERMISSIONS.FO_SPONSORED_BRANDS_DELETE,
    PERMISSIONS.FO_SPONSORED_BRANDS_DETAILS,
    PERMISSIONS.FO_INSIGHTS_LIST,
    PERMISSIONS.FO_INSIGHTS_DOWNLOAD,
    PERMISSIONS.FO_DISPLAY_CAMPAIGNS_LIST_ACCESS,
    PERMISSIONS.FO_DISPLAY_CAMPAIGNS_DETAILS_ACCESS,
    PERMISSIONS.FO_DISPLAY_CAMPAIGNS_CREATE_ACCESS,
  ],
  admin: [
    PERMISSIONS.BO_MAIN_CAMPAIGN_ACCESS,
    PERMISSIONS.BO_PACKAGES_ACCESS,
    PERMISSIONS.BO_USER_MANAGEMENT_ACCESS,
    PERMISSIONS.BO_OFFLINE_ADVERTISING_ACCESS,
    PERMISSIONS.BO_INSIGHTS_ACCESS,
    PERMISSIONS.BO_TIER1_EVENTS_ACCESS,
    PERMISSIONS.BO_DISPLAY_CAMPAIGNS_MODERATE_ACCESS,
    PERMISSIONS.BO_SPONSORED_PRODUCTS_LIST,
    PERMISSIONS.BO_SPONSORED_PRODUCTS_DELETE,
    PERMISSIONS.BO_SPONSORED_PRODUCTS_DETAILS,
    PERMISSIONS.BO_SPONSORED_PRODUCTS_MODERATE,
    PERMISSIONS.BO_SPONSORED_PRODUCTS_SETTINGS_MODERATE,
    PERMISSIONS.BO_SPONSORED_PRODUCTS_SETTINGS_MODERATE_VIEW,
    PERMISSIONS.BO_SPONSORED_BRANDS_LIST,
    PERMISSIONS.BO_SPONSORED_BRANDS_DELETE,
    PERMISSIONS.BO_SPONSORED_BRANDS_DETAILS,
    PERMISSIONS.BO_SPONSORED_BRANDS_MODERATE,
    PERMISSIONS.BO_SPONSORED_BRANDS_SETTINGS_MODERATE,
    PERMISSIONS.BO_SPONSORED_BRANDS_SETTINGS_MODERATE_VIEW,
    PERMISSIONS.BO_SPONSORED_PRODUCTS_EDIT,
    PERMISSIONS.BO_PACKAGES_LIST,
    PERMISSIONS.BO_PACKAGES_EDIT,
    PERMISSIONS.BO_USER_MANAGEMENT_LIST,
    PERMISSIONS.BO_USER_MANAGEMENT_EDIT,
    PERMISSIONS.BO_MODULES_MODERATE,
    PERMISSIONS.BO_INSIGHTS_LIST,
    PERMISSIONS.BO_INSIGHTS_CREATE,
    PERMISSIONS.BO_INSIGHTS_DELETE,
    PERMISSIONS.BO_INSIGHTS_DOWNLOAD,
    PERMISSIONS.BO_DISPLAY_CAMPAIGNS_LIST_ACCESS,
    PERMISSIONS.BO_DISPLAY_CAMPAIGNS_DETAILS_ACCESS,
    PERMISSIONS.BO_DISPLAY_CAMPAIGNS_MODERATE_ACCESS,
    PERMISSIONS.BO_SPONSORED_PRODUCTS_FINANCE,
  ],
  guru: [
    PERMISSIONS.FO_MAIN_CAMPAIGN_ACCESS,
    PERMISSIONS.FO_OFFLINE_ADVERTISING_ACCESS,
    PERMISSIONS.FO_INSIGHTS_ACCESS,
    PERMISSIONS.FO_FINANCE_ACCESS,
    PERMISSIONS.FO_TOPUP_ACCESS,
    PERMISSIONS.FO_TIER1_EVENTS_ACCESS,
    PERMISSIONS.FO_SPONSORED_PRODUCTS_LIST,
    PERMISSIONS.FO_SPONSORED_PRODUCTS_CREATE,
    PERMISSIONS.FO_SPONSORED_PRODUCTS_EDIT,
    PERMISSIONS.FO_SPONSORED_PRODUCTS_DELETE,
    PERMISSIONS.FO_SPONSORED_PRODUCTS_DETAILS,
    PERMISSIONS.FO_SPONSORED_PRODUCTS_REPORTS_LIST,
    PERMISSIONS.FO_SPONSORED_PRODUCTS_REPORTS_EXPORT,
    PERMISSIONS.FO_SPONSORED_PRODUCTS_FINANCE,
    PERMISSIONS.FO_SPONSORED_PRODUCTS_FINANCE_TOPUP,
    PERMISSIONS.FO_SPONSORED_BRANDS_LIST,
    PERMISSIONS.FO_SPONSORED_BRANDS_CREATE,
    PERMISSIONS.FO_SPONSORED_BRANDS_EDIT,
    PERMISSIONS.FO_SPONSORED_BRANDS_DELETE,
    PERMISSIONS.FO_SPONSORED_BRANDS_DETAILS,
    PERMISSIONS.FO_INSIGHTS_LIST,
    PERMISSIONS.FO_INSIGHTS_DOWNLOAD,
    PERMISSIONS.BO_MAIN_CAMPAIGN_ACCESS,
    PERMISSIONS.BO_PACKAGES_ACCESS,
    PERMISSIONS.BO_USER_MANAGEMENT_ACCESS,
    PERMISSIONS.BO_OFFLINE_ADVERTISING_ACCESS,
    PERMISSIONS.BO_INSIGHTS_ACCESS,
    PERMISSIONS.BO_TIER1_EVENTS_ACCESS,
    PERMISSIONS.BO_SPONSORED_PRODUCTS_LIST,
    PERMISSIONS.BO_SPONSORED_PRODUCTS_DELETE,
    PERMISSIONS.BO_SPONSORED_PRODUCTS_DETAILS,
    PERMISSIONS.BO_SPONSORED_PRODUCTS_MODERATE,
    PERMISSIONS.BO_SPONSORED_PRODUCTS_SETTINGS_MODERATE,
    PERMISSIONS.BO_SPONSORED_PRODUCTS_SETTINGS_MODERATE_VIEW,
    PERMISSIONS.BO_SPONSORED_BRANDS_LIST,
    PERMISSIONS.BO_SPONSORED_BRANDS_DELETE,
    PERMISSIONS.BO_SPONSORED_BRANDS_DETAILS,
    PERMISSIONS.BO_SPONSORED_BRANDS_MODERATE,
    PERMISSIONS.BO_SPONSORED_BRANDS_SETTINGS_MODERATE,
    PERMISSIONS.BO_SPONSORED_BRANDS_SETTINGS_MODERATE_VIEW,
    PERMISSIONS.BO_SPONSORED_PRODUCTS_EDIT,
    PERMISSIONS.BO_PACKAGES_LIST,
    PERMISSIONS.BO_PACKAGES_EDIT,
    PERMISSIONS.BO_USER_MANAGEMENT_LIST,
    PERMISSIONS.BO_USER_MANAGEMENT_EDIT,
    PERMISSIONS.BO_MODULES_MODERATE,
    PERMISSIONS.BO_INSIGHTS_LIST,
    PERMISSIONS.BO_INSIGHTS_CREATE,
    PERMISSIONS.BO_INSIGHTS_DELETE,
    PERMISSIONS.BO_INSIGHTS_DOWNLOAD,
    PERMISSIONS.BO_DISPLAY_CAMPAIGNS_LIST_ACCESS,
    PERMISSIONS.BO_DISPLAY_CAMPAIGNS_DETAILS_ACCESS,
    PERMISSIONS.BO_DISPLAY_CAMPAIGNS_MODERATE_ACCESS,
    PERMISSIONS.FO_DISPLAY_CAMPAIGNS_LIST_ACCESS,
    PERMISSIONS.FO_DISPLAY_CAMPAIGNS_DETAILS_ACCESS,
    PERMISSIONS.FO_DISPLAY_CAMPAIGNS_CREATE_ACCESS,
    PERMISSIONS.BO_SPONSORED_PRODUCTS_FINANCE,
  ],
}
