import BarChartIcon from '@mui/icons-material/BarChart'
import CampaignIcon from '@mui/icons-material/Campaign'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import SettingsIcon from '@mui/icons-material/Settings'
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined'
import { SectionType } from './types'
import { PERMISSIONS } from '~/constants/permissions'
import { ROUTE_PATHS } from '~/constants/routes'
import useModuleSettings from '~/hooks/useModuleSettings'
import { ReactComponent as AnalyticIcon } from '~/images/analytics.svg'
import { ReactComponent as Billboard } from '~/images/billboard.svg'
import { ReactComponent as MegafoneIcon } from '~/images/megaphone.svg'
import { ReactComponent as PackageIcon } from '~/images/package.svg'
import { ReactComponent as ProductIcon } from '~/images/product.svg'
import { ReactComponent as SponsordBrands } from '~/images/sponsordBrands.svg'
import { ReactComponent as Tier1Events } from '~/images/tier1Events.svg'
import { ReactComponent as UserIcon } from '~/images/userCard.svg'
import { SETTINGS_LIST } from '~/services/settings/constants'
import { i18n } from '~/translations/i18n'

const useSections = () => {
  const { list: moduleSettings } = useModuleSettings()
  const listMap: { [key: string]: boolean } = {}
  moduleSettings.forEach((item) => {
    listMap[item.id] = item.isEnabled
  })

  const sections: SectionType[] = [
    {
      links: [
        {
          label: `${i18n.t('titles.packages')}`,
          enabled: true,
          href: ROUTE_PATHS.BO_ROUTES.PACKAGES_LIST,
          icon: <PackageIcon />,
          permissions: [
            PERMISSIONS.BO_PACKAGES_LIST,
            PERMISSIONS.BO_PACKAGES_ACCESS, // TODO: remove this permission after validating that it is not used
          ],
        },
        {
          label: `${i18n.t('titles.advertisers')}`,
          enabled: true,
          href: ROUTE_PATHS.BO_ROUTES.ADVERTISERS,
          icon: <UserIcon />,
          permissions: [
            PERMISSIONS.BO_USER_MANAGEMENT_LIST,
            PERMISSIONS.BO_USER_MANAGEMENT_ACCESS, // TODO: remove this permission after validating that it is not used
          ],
        },
        {
          label: `${i18n.t('titles.sponsoredProducts')}`,
          enabled: listMap?.[SETTINGS_LIST.SPONSORED_PRODUCTS],
          href: '',
          icon: <ProductIcon />,
          permissions: [
            PERMISSIONS.FO_SPONSORED_PRODUCTS_LIST,
            PERMISSIONS.BO_SPONSORED_PRODUCTS_LIST,
            PERMISSIONS.FO_SPONSORED_PRODUCTS_REPORTS_LIST,
            PERMISSIONS.BO_SPONSORED_PRODUCTS_SETTINGS_MODERATE_VIEW,
          ],
          subLinks: [
            {
              label: `${i18n.t('titles.campaigns')}`,
              enabled: listMap?.[SETTINGS_LIST.SPONSORED_PRODUCTS],
              icon: <ProductIcon />,
              href: ROUTE_PATHS.SPONSORED_PRODUCTS_LIST,
              permissions: [
                PERMISSIONS.FO_SPONSORED_PRODUCTS_LIST,
                PERMISSIONS.BO_SPONSORED_PRODUCTS_LIST,
              ],
            },
            {
              label: `${i18n.t('titles.performanceReport')}`,
              enabled: true, // TODO we should have a permission for this in listMap
              icon: <BarChartIcon />,
              href: ROUTE_PATHS.SPONSORED_PRODUCTS_REPORTS,
              permissions: [PERMISSIONS.FO_SPONSORED_PRODUCTS_REPORTS_LIST],
            },
            {
              label: `${i18n.t('titles.SponsoredProductsSettings')}`,
              enabled: true, // TODO we should have a permission for this in listMap
              icon: <SettingsIcon />,
              href: ROUTE_PATHS.SPONSORED_PRODUCTS_SETTINGS,
              permissions: [
                PERMISSIONS.BO_SPONSORED_PRODUCTS_SETTINGS_MODERATE_VIEW,
              ],
            },
          ],
        },
        {
          label: `${i18n.t('titles.sponsoredBrands')}`,
          href: ROUTE_PATHS.SPONSORED_BRANDS_LIST,
          enabled: listMap[SETTINGS_LIST.SPONSORED_BRANDS],
          icon: <SponsordBrands />,
          permissions: [
            PERMISSIONS.FO_SPONSORED_BRANDS_LIST,
            PERMISSIONS.BO_SPONSORED_BRANDS_LIST,
          ],
        },
        {
          label: `${i18n.t('titles.displayCampaigns')}`,
          href: '',
          enabled: listMap[SETTINGS_LIST.SPONSORED_BRANDS], // TODO: this is a temporary till we have permissions
          icon: <CampaignIcon />, // it may be changed
          permissions: [
            PERMISSIONS.FO_DISPLAY_CAMPAIGNS_LIST_ACCESS,
            PERMISSIONS.BO_DISPLAY_CAMPAIGNS_LIST_ACCESS,
          ],
          subLinks: [
            {
              label: `${i18n.t('titles.displayCampaigns')}`,
              href: ROUTE_PATHS.DISPLAY_CAMPAIGNS_LIST,
              enabled: listMap[SETTINGS_LIST.SPONSORED_BRANDS], // TODO: this is a temporary till we have permissions
              icon: <CampaignIcon />, // it may be changed
              permissions: [
                PERMISSIONS.FO_DISPLAY_CAMPAIGNS_LIST_ACCESS,
                PERMISSIONS.BO_DISPLAY_CAMPAIGNS_LIST_ACCESS,
              ],
            },
            {
              label: `${i18n.t('titles.SponsoredProductsSettings')}`,
              enabled: true, // TODO we should have a permission for this in listMap
              icon: <SettingsIcon />,
              href: ROUTE_PATHS.DISPLAY_CAMPAIGNS_SETTINGS,
              permissions: [PERMISSIONS.BO_DISPLAY_CAMPAIGNS_LIST_ACCESS],
            },
          ],
        },
        {
          label: `${i18n.t('titles.offlineAdvertising')}`,
          href: ROUTE_PATHS.OFFLINE_ADVERTISING_LIST,
          enabled: listMap[SETTINGS_LIST.OFFLINE_ADVERTISING],
          icon: <Billboard />,
          permissions: [
            PERMISSIONS.FO_OFFLINE_ADVERTISING_ACCESS,
            PERMISSIONS.BO_OFFLINE_ADVERTISING_ACCESS,
          ],
        },
        {
          label: `${i18n.t('titles.tierEvents')}`,
          href: ROUTE_PATHS.TIER_EVENTS_LIST,
          enabled: listMap[SETTINGS_LIST.TIER_1],
          icon: <Tier1Events />,
          permissions: [
            PERMISSIONS.FO_TIER1_EVENTS_ACCESS,
            PERMISSIONS.BO_TIER1_EVENTS_ACCESS,
          ],
        },
        {
          label: `${i18n.t('titles.documents')}`,
          href: ROUTE_PATHS.REPORTS_LIST,
          enabled: listMap[SETTINGS_LIST.CAMPAIGN_REPORTS],
          icon: <TextSnippetOutlinedIcon />,
          permissions: [
            PERMISSIONS.FO_INSIGHTS_LIST,
            PERMISSIONS.FO_INSIGHTS_ACCESS, // TODO: will be deleted after the new permissions will be added
          ],
        },
        {
          label: `${i18n.t('titles.campaigns')}`,
          enabled: true,
          href: ROUTE_PATHS.CAMPAIGN_LIST,
          icon: <MegafoneIcon />,
          permissions: [''],
          subLinks: [],
        },
        {
          label: `${i18n.t('titles.insights')}`,
          href: ROUTE_PATHS.INSIGHTS_LIST,
          enabled: listMap[SETTINGS_LIST.INSIGHTS],
          icon: <AnalyticIcon />,
          permissions: [
            PERMISSIONS.FO_INSIGHTS_LIST,
            PERMISSIONS.BO_INSIGHTS_LIST,
            PERMISSIONS.FO_INSIGHTS_ACCESS, // TODO: will be deleted after the new permissions will be added
            PERMISSIONS.BO_INSIGHTS_ACCESS, // TODO: will be deleted after the new permissions will be added
          ],
        },
        {
          label: `${i18n.t('titles.finance')}`,
          href: ROUTE_PATHS.FINANCE_LIST,
          enabled: listMap[SETTINGS_LIST.FINANCE],
          icon: <CreditCardIcon />,
          permissions: [
            PERMISSIONS.FO_SPONSORED_PRODUCTS_FINANCE,
            PERMISSIONS.BO_SPONSORED_PRODUCTS_FINANCE,
          ],
        },
        {
          label: `${i18n.t('titles.settings')}`,
          href: ROUTE_PATHS.SETTINGS,
          enabled: true,
          icon: <SettingsIcon />,
          permissions: [
            PERMISSIONS.BO_MODULES_MODERATE,
            PERMISSIONS.BO_SETTINGS_ACCESS, // TODO: remove this permission after validating that it is not used
          ],
        },
      ],
    },
  ]

  return {
    sections,
  }
}

export default useSections
